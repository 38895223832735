import React from "react"

import logo from "../images/iskandar-widjaja.png"

const Intro = () => (
  <div id="intro">
    <h1>
      <img src={logo} alt="Iskandar Widjaja" />
    </h1>
  </div>
)

export default Intro
