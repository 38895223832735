import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Spotify from "../components/spotify"
import Instagram from "../components/instagram"
import Intro from "../components/intro"
import Footer from "../components/footer"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Iskandar Widjaja" />
    <Intro />
    <Spotify />
    <Footer contacts={data.allContentfulContact.edges} />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query Index {
    allContentfulContact {
      edges {
        node {
          id
          title
          contact {
            contact
          }
        }
      }
    }
    allContentfulBlogPost {
      edges {
        node {
          date
          title
          content {
            content
          }
        }
      }
    }
    allContentfulPhoto {
      edges {
        node {
          photo {
            resize(height: 100, width: 100) {
              src
            }
          }
        }
      }
    }
  }
`
