import React from "react"

const Spotify = () => (
  <div id="spotify">
    <section>
      <h2>Music</h2>
      <iframe
        src="https://open.spotify.com/embed/artist/1UYYiIEPhxozP9CtgXHhYD"
        width="650"
        height="380"
        frameBorder="0"
        allowtransparency="true"
      ></iframe>
    </section>
  </div>
)

export default Spotify
